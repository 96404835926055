<template>
  <v-container fluid class="content-wrapper mb-5" style="overflow-y: hidden">
    <!-- <div class="overflow-y-auto d-flex flex-column pa-3"> -->
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600">{{ $t("SigninPolicy.title") }}</h4>
      <v-btn
        icon
        class="ml-3"
        @click="initAll()"
        :class="loadingData && 'animate__spining'"
        :disabled="loadingData"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <!-- loading data -->
    <v-row v-if="loadingData" row wrap style="margin-top: -10px">
      <v-col cols="12" sm="12" md="12" lg="12" class="row" v-for="n in 3" :key="n">
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="8" md="8" lg="8"> </v-col>
        <!-- <div class="row"> -->
        <v-col v-for="m in 2" :key="`mock_${m}`" cols="12" sm="6" md="6" lg="6">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-col>
        <!-- </div> -->
      </v-col>
    </v-row>
    <!-- show policy data -->
    <v-row v-else row wrap style="margin-top: -10px">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="d-flex flex-column justify-end align-start"
      >
        <h3 class="title-sm mb-0">{{ $t("SigninPolicy.title_policy_changedate1") }}</h3>
      </v-col>
      <template v-for="item in itemsPolicychangedate">
        <v-col
          :key="item._id"
          cols="12"
          sm="12"
          md="6"
          lg="6"
          class="d-flex flex-column justify-end align-start"
        >
          <!-- <div class="card card-shadow pa-5 box-content-policty"> -->
          <v-card class="box-content-policty">
            <h3 class="title-sm ma-3">
              {{ $t("SigninPolicy.title_policy_changedate21") }}
              {{ item.free_before }}
              {{ $t("SigninPolicy.title_policy_changedate3") }}
            </h3>
            <!-- <v-card-title
              >{{ $t("SigninPolicy.title_policy_changedate21") }}
              {{ item.free_before }}
              {{ $t("SigninPolicy.title_policy_changedate3") }}
            </v-card-title>-->

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="8" lg="8">
                  <div>
                    <div class="label-policy">
                      {{ $t("SigninPolicy.label_policy_changedate1") }}
                      {{ item.free_before }}
                      {{ $t("SigninPolicy.label_policy_changedate2") }}
                      {{ item.free_before }}
                      {{
                        item.free_before > 1
                          ? `${$t("SigninPolicy.dialog_policy_label3")} ${
                              item.can_before
                            } ${$t("SigninPolicy.dialog_policy_label4")}`
                          : $t("SigninPolicy.dialog_policy_label3_0")
                      }}
                    </div>
                    <div class="label-policy">
                      {{ $t("SigninPolicy.label_policy_changedate5") }}
                    </div>
                    <span>
                      <span class="txt-bold">
                        {{ $t("SigninPolicy.title_note") }}
                      </span>
                      <span class="label-policy">{{
                        $t("SigninPolicy.label_policy_changedate6")
                      }}</span>
                    </span>
                  </div>
                  <div class="d-flex flex-row mt-3">
                    <v-btn
                      color="#666ee8"
                      dark
                      class="mr-3"
                      @click="initPolicyChangeDateSelectOld(item)"
                      >{{ $t("SigninPolicy.btn_edit") }}</v-btn
                    >
                    <v-btn
                      v-if="
                        checkremove == true && checkInUsedPolicyRatePlanFunc(item) == true
                      "
                      color="red lighten-2"
                      dark
                      @click="removeChangeDateFunc(item)"
                      >{{ $t("SigninPolicy.btn_delete") }}</v-btn
                    >
                  </div>
                </v-col>
                <div class="my-5">
                  <v-divider vertical></v-divider>
                </div>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <div class="d-flex flex-column">
                    <span>{{ $t("SigninPolicy.title_report_changedate1") }}</span>
                    <span class="txt-bold"></span>

                    <span class="mt-3">{{
                      $t("SigninPolicy.title_report_changedate2")
                    }}</span>
                    <span class="txt-bold"></span>

                    <span class="mt-3">{{
                      $t("SigninPolicy.title_report_changedate3")
                    }}</span>
                    <span class="txt-bold"></span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- </div> -->
        </v-col>
      </template>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        class="d-flex flex-column justify-end align-start"
      >
        <!-- <div class="card card-shadow pa-5"> -->
        <v-card class="box-content-policty">
          <v-card-title>
            {{ $t("SigninPolicy.title_cant_changedate1") }}
          </v-card-title>

          <v-card-text style="height: 100%">
            <v-row style="height: 100%">
              <v-col cols="12" sm="12" md="8" lg="8" style="height: 100%">
                <div class="label-policy">
                  {{ $t("SigninPolicy.label_cant_changedate1") }}
                </div>
              </v-col>
              <div>
                <v-divider vertical></v-divider>
              </div>
              <v-col cols="12" sm="12" md="4" lg="4" style="height: 100%">
                <div class="d-flex flex-column">
                  <span>{{ $t("SigninPolicy.title_report_changedate1") }}</span>
                  <span class="txt-bold"></span>

                  <span class="mt-3">{{
                    $t("SigninPolicy.title_report_changedate2")
                  }}</span>
                  <span class="txt-bold"></span>

                  <span class="mt-3">{{
                    $t("SigninPolicy.title_report_changedate3")
                  }}</span>
                  <span class="txt-bold"></span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- </div> -->
      </v-col>
      <v-col
        v-if="quotaPolicychangedate - itemsPolicychangedate.length"
        cols="12"
        sm="12"
        md="6"
        lg="6"
        class="d-flex flex-column justify-end align-start"
      >
        <!-- <div class="card card-shadow pa-5 box-content-policty"> -->
        <v-card class="box-content-policty">
          <v-card-text
            style="height: 100%"
            class="d-flex flex-column align-center justify-center"
          >
            <div class="text-center">
              {{ $t("SigninPolicy.youcanaddmore1") }}
              {{ quotaPolicychangedate - itemsPolicychangedate.length }}
              {{ $t("SigninPolicy.youcanaddmore2") }}
            </div>
            <v-dialog v-model="dialogAddPolicychangedate" width="64rem">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="width: 100%"
                  color="#666ee8"
                  class="mt-3"
                  v-bind="attrs"
                  v-on="on"
                  dark
                >
                  <v-icon icon left>mdi-plus-circle-outline</v-icon>
                  {{ $t("SigninPolicy.btn_add_policy") }}
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 lighten-2">
                  <span>{{ $t("SigninPolicy.title_dialog_changedate") }}</span>
                  <v-spacer />
                  <v-btn color="red lighten-2" icon @click="closeDialogchangedateFunc()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <div>{{ $t("SigninPolicy.label_dialog_changedate1") }}</div>
                  <div>
                    <span>
                      {{ $t("SigninPolicy.label_dialog_changedate2") }}
                      <span class="txt-bold">{{
                        $t("SigninPolicy.label_dialog_changedate3")
                      }}</span>
                      {{ $t("SigninPolicy.label_dialog_changedate4") }}
                      <span class="txt-bold">{{
                        $t("SigninPolicy.label_dialog_changedate5")
                      }}</span>
                      {{ $t("SigninPolicy.label_dialog_changedate6") }}
                      <span class="txt-bold">{{
                        $t("SigninPolicy.label_dialog_changedate7")
                      }}</span>
                      {{ $t("SigninPolicy.label_dialog_changedate8") }}
                    </span>
                  </div>
                  <div class="mt-5">
                    <span>{{ $t("SigninPolicy.label_dialog_nofee_changedate") }}</span>
                    <div
                      class="dropdown select mt-3"
                      @click="getfocus('freechangedate')"
                      @mouseleave="mouseOver('freechangedate')"
                    >
                      <a data-toggle="dropdown" data-display="static" id="freechangedate">
                        <v-select
                          v-model="dialogPolicychangedateData.free_before"
                          :items="itemsSelectFreeBeforeFunc"
                          item-text="name"
                          item-value="value"
                          label
                          :placeholder="$t('SignupStep.select_one')"
                          :no-data-text="$t('SignupStep.nodata')"
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          @change="
                            checkChangeDateItems(dialogPolicychangedateData, 'end')
                          "
                        >
                          <template v-slot:item="data">
                            <v-list-item-content
                              @mouseenter="getfocus('freechangedate')"
                              @mouseleave="mouseOver('freechangedate')"
                            >
                              <v-list-item-title
                                v-html="data.item.name"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </a>
                    </div>
                  </div>

                  <div
                    v-if="
                      dialogPolicychangedateData.free_before > 1 ||
                      !dialogPolicychangedateData.free_before
                    "
                    class="mt-5"
                  >
                    <span>
                      {{ $t("SigninPolicy.label_dialog_fee_changedate1") }}
                      {{ dialogPolicychangedateData.free_before }}
                      {{ $t("SigninPolicy.label_dialog_fee_changedate2") }}
                    </span>
                    <input
                      type="number"
                      min="0"
                      @keypress="isNumber($event)"
                      class="form-control mt-3"
                      placeholder
                      name
                      v-model="dialogPolicychangedateData.fee"
                    />
                  </div>

                  <div
                    v-if="
                      dialogPolicychangedateData.free_before > 1 ||
                      !dialogPolicychangedateData.free_before
                    "
                    class="mt-5"
                  >
                    <span>{{ $t("SigninPolicy.label_dialog_cantchagedate") }}</span>
                    <div
                      class="dropdown select mt-3"
                      @click="getfocus('freechangedate')"
                      @mouseleave="mouseOver('freechangedate')"
                    >
                      <a data-toggle="dropdown" data-display="static" id="freechangedate">
                        <v-select
                          v-model="dialogPolicychangedateData.can_before"
                          :items="itemsCanBeforeFunc"
                          item-text="name"
                          item-value="value"
                          label
                          :placeholder="$t('SignupStep.select_one')"
                          :no-data-text="$t('SignupStep.nodata')"
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          @change="
                            checkChangeDateItems(dialogPolicychangedateData, 'start')
                          "
                        >
                          <template v-slot:item="data">
                            <v-list-item-content
                              @mouseenter="getfocus('freechangedate')"
                              @mouseleave="mouseOver('freechangedate')"
                            >
                              <v-list-item-title
                                v-html="data.item.name"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </a>
                    </div>
                  </div>

                  <div class="mt-5">
                    <div class="txt-bold">
                      {{ $t("SigninPolicy.dialog_policy_title") }}
                    </div>
                    <span>
                      {{ $t("SigninPolicy.dialog_policy_label1") }}
                      {{ dialogPolicychangedateData.free_before }}
                      {{ $t("SigninPolicy.dialog_policy_label2") }}
                      {{ dialogPolicychangedateData.free_before }}
                      {{
                        dialogPolicychangedateData.free_before > 1
                          ? `${$t("SigninPolicy.dialog_policy_label3")} ${
                              dialogPolicychangedateData.can_before
                            } ${$t("SigninPolicy.dialog_policy_label4")}`
                          : $t("SigninPolicy.dialog_policy_label3_0")
                      }}
                    </span>
                  </div>

                  <div class="mt-5">
                    <div class="txt-bold">
                      {{ $t("SigninPolicy.title_payment_policy") }}
                    </div>
                    <div class="box-note-dialog pa-5 mt-1">
                      <div>{{ $t("SigninPolicy.label_payment_policy") }}</div>
                      <span>
                        <span class="txt-bold">
                          {{ $t("SigninPolicy.note_payment_policy") }}
                        </span>
                        {{ $t("SigninPolicy.label_note_payment_policy") }}
                      </span>
                    </div>
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red lighten-2"
                    outlined
                    @click="closeDialogchangedateFunc()"
                    >{{ $t("SigninPolicy.btn_cancel") }}</v-btn
                  >
                  <v-btn
                    color="#666ee8"
                    dark
                    outlined
                    :disabled="
                      (dialogPolicychangedateData.free_before > 1 &&
                        !dialogPolicychangedateData.can_before) ||
                      dialogPolicychangedateData.fee < 0 ||
                      !dialogPolicychangedateData.free_before
                    "
                    @click="addNewPolicychangedateFunc()"
                    >{{ $t("SigninPolicy.btn_save") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
        <!-- </div> -->
      </v-col>
      <!-- Extra bed -->
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="d-flex flex-column justify-end align-start"
      >
        <h3 class="title-sm mb-0">{{ $t("SigninPolicy.title_extrabed_child1") }}</h3>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        class="d-flex flex-column justify-end align-start"
      >
        <div class="card card-shadow pa-5 box-content-policty">
          <!-- <v-card class="box-content-policty"> -->
          <v-card-text class="pa-0">
            <div class="label-policy mb-3">
              {{ $t("SigninPolicy.title_extrabed_child2") }}
            </div>
            <template v-for="item in itemsPolicyExtraBedChild">
              <div
                :key="item._id"
                v-if="item.age.min == item.age.max"
                class="label-policy mb-3"
              >
                {{ $t("SigninPolicy.label_extrabed_child11") }} {{ item.age.min }}
                {{ $t("SigninPolicy.label_extrabed_child12") }}
                {{ $t("SigninPolicy.label_extrabed_child13") }}
                {{ item.percent }}
                {{ $t("SigninPolicy.label_extrabed_child14") }}
                {{ $t("SigninPolicy.label_extrabed_child15") }}
              </div>
              <div :key="item._id" v-else class="label-policy mb-3">
                {{ $t("SigninPolicy.label_extrabed_child11") }}
                {{ item.age.min }}
                {{ $t("SigninPolicy.label_checkinout_time2") }}
                {{ item.age.max }}
                {{ $t("SigninPolicy.label_extrabed_child12") }}
                {{ $t("SigninPolicy.label_extrabed_child13") }}
                {{ item.percent }}
                {{ $t("SigninPolicy.label_extrabed_child14") }}
                {{ $t("SigninPolicy.label_extrabed_child15") }}
              </div>
            </template>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-btn color="#666ee8" depressed dark @click="editDialogExtraBedChild()">{{
              $t("SigninPolicy.btn_edit")
            }}</v-btn>
          </v-card-actions>
          <!-- </v-card> -->
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="0"
        md="6"
        lg="6"
        class="d-flex flex-column justify-end align-start"
      ></v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="d-flex flex-column justify-end align-start"
      >
        <h3 class="title-sm mb-0">{{ $t("SigninPolicy.title_other_policy") }}</h3>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        class="d-flex flex-column justify-end align-start"
      >
        <v-card class="box-content-policty">
          <v-card-text>
            <div class="mb-3">{{ $t("SigninPolicy.title_nofee_cancel1") }}</div>
            <span class="label-policy">
              <li v-if="policyCancelBooking" style="width: fit-content !important">
                {{ $t("SigninPolicy.label_nofee_cancel1") }}
              </li>
              <li v-else style="width: fit-content !important">
                {{ $t("SigninPolicy.label_nofee_cancel2") }}
              </li>
            </span>
          </v-card-text>

          <v-card-actions>
            <v-dialog v-model="dialogCancelBooking" width="64rem">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#666ee8" class="mt-3" dark v-bind="attrs" v-on="on">{{
                  $t("SigninPolicy.btn_edit")
                }}</v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 lighten-2">
                  <span>{{ $t("SigninPolicy.title_nofee_cancel1") }}</span>
                  <v-spacer />
                  <v-btn
                    color="red lighten-2"
                    icon
                    @click="closeDialogcancelbookingFunc()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <span>{{ $t("SigninPolicy.title_nofee_cancel2") }}</span>
                  <v-radio-group v-model="policyCancelBooking">
                    <v-radio
                      :label="$t('SigninPolicy.label_nofee_cancel1')"
                      :value="true"
                    ></v-radio>
                    <v-radio
                      :label="$t('SigninPolicy.label_nofee_cancel2')"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red lighten-2"
                    dark
                    @click="closeDialogcancelbookingFunc()"
                    >{{ $t("SigninPolicy.btn_cancel") }}</v-btn
                  >
                  <v-btn color="#666ee8" dark @click="saveDialogcancelbookingFunc()">{{
                    $t("SigninPolicy.btn_save")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        class="d-flex flex-column justify-end align-start"
      >
        <v-card class="box-content-policty">
          <v-card-text>
            <div class="mb-3">{{ $t("SigninPolicy.title_customer_detail") }}</div>
            <div>{{ $t("SigninPolicy.title_checkinandout") }}</div>
            <span class="label-policy mb-3">
              <li v-if="policyCustomerData.check_in_range.is24hours">
                {{ $t("SigninPolicy.label_checkin_24hr") }}
              </li>
              <li v-else>
                {{ $t("SigninPolicy.label_checkinout_time1") }}
                {{ policyCustomerData.check_in_range.min }}
                {{ $t("SigninPolicy.label_checkinout_time2") }}
                {{ policyCustomerData.check_in_range.max }}
              </li>
              <li v-if="policyCustomerData.check_out_range.is24hours">
                {{ $t("SigninPolicy.label_checkout_24hr") }}
              </li>
              <li v-else>
                {{ $t("SigninPolicy.label_checkinout_time12") }}
                {{ policyCustomerData.check_out_range.min }}
                {{ $t("SigninPolicy.label_checkinout_time2") }}
                {{ policyCustomerData.check_out_range.max }}
              </li>
            </span>
            <div>{{ $t("SigninPolicy.title_limit_age") }}</div>
            <span class="label-policy">
              <div v-if="policyCustomerData.age.isStrict">
                <li v-if="policyCustomerData.age.min">
                  {{ $t("SigninPolicy.title_age_above") }}
                  {{ policyCustomerData.age.min }}
                </li>
                <li v-if="policyCustomerData.age.max">
                  {{ $t("SigninPolicy.title_age_under") }}
                  {{ policyCustomerData.age.max }}
                </li>
              </div>
              <li v-else>{{ $t("SigninPolicy.label_no_limit_age") }}</li>
            </span>
          </v-card-text>

          <v-card-actions>
            <v-dialog v-model="dialogCustomer" width="64rem">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#666ee8" class="mt-3" dark v-bind="attrs" v-on="on">{{
                  $t("SigninPolicy.btn_edit")
                }}</v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 lighten-2">
                  <span>{{ $t("SigninPolicy.title_customer_detail") }}</span>
                  <v-spacer />
                  <v-btn color="red lighten-2" icon @click="closeDialogcustomerFunc()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="d-flex flex-column">
                  <span class="title-md">{{ $t("SigninPolicy.title_checkinout") }}</span>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      class="d-flex align-end justify-start"
                    >
                      <span class="mb-0">{{
                        $t("SigninPolicy.label_checkin_time")
                      }}</span>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-checkbox
                        v-model="policyCustomerData.check_in_range.is24hours"
                        :label="$t('SigninPolicy.label_checkin_24hr')"
                        style="margin-bottom: 0 !important"
                        dense
                        hide-details
                        @change="checkIn24hours()"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <div
                        class="dropdown select"
                        @click="getfocus('checkinstart')"
                        @mouseleave="mouseOver('checkinstart')"
                      >
                        <a data-toggle="dropdown" data-display="static" id="checkinstart">
                          <v-select
                            v-model="policyCustomerData.check_in_range.min"
                            :items="itemsCheckInOut"
                            item-text="name"
                            item-value="value"
                            label
                            :placeholder="$t('SignupStep6.title_checkin')"
                            :no-data-text="$t('SignupStep.nodata')"
                            color="pink"
                            solo
                            flat
                            dense
                            single-line
                            hide-details
                            :disabled="policyCustomerData.check_in_range.is24hours"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content
                                @mouseenter="getfocus('checkinstart')"
                                @mouseleave="mouseOver('checkinstart')"
                              >
                                <v-list-item-title
                                  v-html="data.item.name"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </a>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <div
                        class="dropdown select"
                        @click="getfocus('checkinend')"
                        @mouseleave="mouseOver('checkinend')"
                      >
                        <a data-toggle="dropdown" data-display="static" id="checkinend">
                          <v-select
                            v-model="policyCustomerData.check_in_range.max"
                            :items="itemsCheckInOut"
                            item-text="name"
                            item-value="value"
                            label
                            :placeholder="$t('SignupStep6.title_checkin')"
                            :no-data-text="$t('SignupStep.nodata')"
                            color="pink"
                            solo
                            flat
                            dense
                            single-line
                            hide-details
                            :disabled="policyCustomerData.check_in_range.is24hours"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content
                                @mouseenter="getfocus('checkinend')"
                                @mouseleave="mouseOver('checkinend')"
                              >
                                <v-list-item-title
                                  v-html="data.item.name"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </a>
                      </div>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      class="d-flex align-end justify-start"
                    >
                      <span class="mb-0">{{
                        $t("SigninPolicy.label_checkout_time")
                      }}</span>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-checkbox
                        v-model="policyCustomerData.check_out_range.is24hours"
                        :label="$t('SigninPolicy.label_checkout_24hr')"
                        style="margin-bottom: 0 !important"
                        dense
                        hide-details
                        @change="checkOut24hours()"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <div
                        class="dropdown select"
                        @click="getfocus('checkoutstart')"
                        @mouseleave="mouseOver('checkoutstart')"
                      >
                        <a
                          data-toggle="dropdown"
                          data-display="static"
                          id="checkoutstart"
                        >
                          <v-select
                            v-model="policyCustomerData.check_out_range.min"
                            :items="itemsCheckInOut"
                            item-text="name"
                            item-value="value"
                            label
                            :placeholder="$t('SignupStep6.title_checkin')"
                            :no-data-text="$t('SignupStep.nodata')"
                            color="pink"
                            solo
                            flat
                            dense
                            single-line
                            hide-details
                            :disabled="policyCustomerData.check_out_range.is24hours"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content
                                @mouseenter="getfocus('checkoutstart')"
                                @mouseleave="mouseOver('checkoutstart')"
                              >
                                <v-list-item-title
                                  v-html="data.item.name"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </a>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <div
                        class="dropdown select"
                        @click="getfocus('checkoutend')"
                        @mouseleave="mouseOver('checkoutend')"
                      >
                        <a data-toggle="dropdown" data-display="static" id="checkoutend">
                          <v-select
                            v-model="policyCustomerData.check_out_range.max"
                            :items="itemsCheckInOut"
                            item-text="name"
                            item-value="value"
                            label
                            :placeholder="$t('SignupStep6.title_checkin')"
                            :no-data-text="$t('SignupStep.nodata')"
                            color="pink"
                            solo
                            flat
                            dense
                            single-line
                            hide-details
                            :disabled="policyCustomerData.check_out_range.is24hours"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content
                                @mouseenter="getfocus('checkoutend')"
                                @mouseleave="mouseOver('checkoutend')"
                              >
                                <v-list-item-title
                                  v-html="data.item.name"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </a>
                      </div>
                    </v-col>
                  </v-row>

                  <span class="title-md mt-10">{{
                    $t("SigninPolicy.title_limit_age")
                  }}</span>
                  <span>{{ $t("SigninPolicy.label_limit_age") }}</span>
                  <v-radio-group
                    v-model="policyCustomerData.age.isStrict"
                    row
                    @change="checkCustomerStrictAge()"
                  >
                    <v-radio :label="$t('SigninPolicy.yes')" :value="true"></v-radio>
                    <v-radio :label="$t('SigninPolicy.no')" :value="false"></v-radio>
                  </v-radio-group>
                  <v-row v-if="policyCustomerData.age.isStrict">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      class="d-flex flex-column align-start justify-start"
                    >
                      <span>{{ $t("SigninPolicy.title_age_above") }}</span>
                      <div
                        class="dropdown select mt-3"
                        @click="getfocus('selectagebefore')"
                        @mouseleave="mouseOver('selectagebefore')"
                        style="width: 100% !important"
                      >
                        <a
                          data-toggle="dropdown"
                          data-display="static"
                          id="selectagebefore"
                        >
                          <v-select
                            v-model="policyCustomerData.age.min"
                            :items="itemsAgePolicyCustomerMin"
                            item-text="name"
                            item-value="value"
                            label
                            :placeholder="$t('SignupStep.select_one')"
                            :no-data-text="$t('SignupStep.nodata')"
                            color="pink"
                            solo
                            flat
                            dense
                            single-line
                            hide-details
                            @change="checkAgeCustomerItems('min')"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content
                                @mouseenter="getfocus('selectagebefore')"
                                @mouseleave="mouseOver('selectagebefore')"
                              >
                                <v-list-item-title
                                  v-html="data.item.name"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </a>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      class="d-flex flex-column align-start justify-start"
                    >
                      <span>{{ $t("SigninPolicy.title_age_under") }}</span>
                      <div
                        class="dropdown select mt-3"
                        @click="getfocus('selectageend')"
                        @mouseleave="mouseOver('selectageend')"
                        style="width: 100% !important"
                      >
                        <a data-toggle="dropdown" data-display="static" id="selectageend">
                          <v-select
                            v-model="policyCustomerData.age.max"
                            :items="itemsAgePolicyCustomerMax"
                            item-text="name"
                            item-value="value"
                            label
                            :placeholder="$t('SignupStep.select_one')"
                            :no-data-text="$t('SignupStep.nodata')"
                            color="pink"
                            solo
                            flat
                            dense
                            single-line
                            hide-details
                            @change="checkAgeCustomerItems('max')"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content
                                @mouseenter="getfocus('selectageend')"
                                @mouseleave="mouseOver('selectageend')"
                              >
                                <v-list-item-title
                                  v-html="data.item.name"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="red lighten-2" dark @click="closeDialogcustomerFunc()">{{
                    $t("SigninPolicy.btn_cancel")
                  }}</v-btn>
                  <v-btn
                    color="#666ee8"
                    dark
                    @click="saveDialogcustomerFunc()"
                    :disabled="
                      !policyCustomerData.check_in_range.min ||
                      !policyCustomerData.check_in_range.max ||
                      !policyCustomerData.check_out_range.min ||
                      !policyCustomerData.check_out_range.max ||
                      (policyCustomerData.age.isStrict &&
                        !policyCustomerData.age.min &&
                        !policyCustomerData.age.max)
                    "
                    >{{ $t("SigninPolicy.btn_save") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        class="d-flex flex-column justify-end align-start"
      >
        <v-card class="box-content-policty">
          <v-card-text>
            <div class="mb-3">{{ $t("SigninPolicy.title_petandcarpark") }}</div>
            <div>{{ $t("SigninPolicy.title_pet") }}</div>
            <span class="label-policy mb-3">
              <li v-if="policyPet">{{ $t("SigninPolicy.allowpet") }}</li>
              <li v-else>{{ $t("SigninPolicy.notallowpet") }}</li>
            </span>
            <div>{{ $t("SigninPolicy.tile_carpark") }}</div>
            <span class="label-policy">
              <li v-if="policyParking">{{ checkParkingData(policyParking) }}</li>
              <li v-else>{{ $t("SigninPolicy.nopark") }}</li>
            </span>
          </v-card-text>

          <v-card-actions>
            <v-dialog v-model="dialogPetParkPolicy" width="64rem">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#666ee8" class="mt-3" dark v-bind="attrs" v-on="on">{{
                  $t("SigninPolicy.btn_edit")
                }}</v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 lighten-2">
                  <span>{{ $t("SigninPolicy.title_petandcarpark") }}</span>
                  <v-spacer />
                  <v-btn color="red lighten-2" icon @click="closeDialogpetparkFunc()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="d-flex flex-column">
                  <span class="title-md">{{ $t("SigninPolicy.title_pet") }}</span>
                  <span>{{ $t("SigninPolicy.label_pet") }}</span>
                  <div
                    class="dropdown select mt-3"
                    @click="getfocus('selectpet')"
                    @mouseleave="mouseOver('selectpet')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectpet">
                      <v-select
                        v-model="policyPet"
                        :items="itemsAllowPet"
                        item-text="name"
                        item-value="value"
                        label
                        :placeholder="$t('SignupStep.select_one')"
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                      >
                        <template v-slot:item="data">
                          <v-list-item-content
                            @mouseenter="getfocus('selectpet')"
                            @mouseleave="mouseOver('selectpet')"
                          >
                            <v-list-item-title
                              v-html="data.item.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </a>
                  </div>

                  <span class="title-md mt-10">{{
                    $t("SigninPolicy.tile_carpark")
                  }}</span>
                  <span>{{ $t("SigninPolicy.label_carpark") }}</span>
                  <div
                    class="dropdown select mt-3"
                    @click="getfocus('selectparking')"
                    @mouseleave="mouseOver('selectparking')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectparking">
                      <v-select
                        v-model="policyParking"
                        :items="itemsParking"
                        item-text="value"
                        item-value="_id"
                        label
                        :placeholder="$t('SignupStep.select_one')"
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                      >
                        <template v-slot:item="data">
                          <v-list-item-content
                            @mouseenter="getfocus('selectparking')"
                            @mouseleave="mouseOver('selectparking')"
                          >
                            <v-list-item-title
                              v-html="data.item.value"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </a>
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="red lighten-2" dark @click="closeDialogpetparkFunc()">{{
                    $t("SigninPolicy.btn_cancel")
                  }}</v-btn>
                  <v-btn color="#666ee8" dark @click="saveDialogpetparkFunc()">{{
                    $t("SigninPolicy.btn_save")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- dialog edit change date data -->
    <v-dialog v-model="dialogPolicychangedate" width="64rem">
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          <span>{{ $t("SigninPolicy.title_dialog_changedate") }}</span>
          <v-spacer />
          <v-btn color="red lighten-2" icon @click="closeDialogchangedateFunc()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div>{{ $t("SigninPolicy.label_dialog_changedate1") }}</div>
          <div>
            <span>
              {{ $t("SigninPolicy.label_dialog_changedate2") }}
              <span class="txt-bold">{{
                $t("SigninPolicy.label_dialog_changedate3")
              }}</span>
              {{ $t("SigninPolicy.label_dialog_changedate4") }}
              <span class="txt-bold">{{
                $t("SigninPolicy.label_dialog_changedate5")
              }}</span>
              {{ $t("SigninPolicy.label_dialog_changedate6") }}
              <span class="txt-bold">{{
                $t("SigninPolicy.label_dialog_changedate7")
              }}</span>
              {{ $t("SigninPolicy.label_dialog_changedate8") }}
            </span>
          </div>
          <div class="mt-5">
            <span>{{ $t("SigninPolicy.label_dialog_nofee_changedate") }}</span>
            <div
              class="dropdown select mt-3"
              @click="getfocus('freechangedate')"
              @mouseleave="mouseOver('freechangedate')"
            >
              <a data-toggle="dropdown" data-display="static" id="freechangedate">
                <v-select
                  v-model="dialogOldPolicychangedateData.free_before"
                  :items="itemsSelectOldFreeBefore"
                  item-text="name"
                  item-value="value"
                  label
                  :placeholder="$t('SignupStep.select_one')"
                  :no-data-text="$t('SignupStep.nodata')"
                  color="pink"
                  solo
                  flat
                  dense
                  single-line
                  hide-details
                  @change="checkChangeOldDateItems(dialogOldPolicychangedateData, 'end')"
                >
                  <template v-slot:item="data">
                    <v-list-item-content
                      @mouseenter="getfocus('freechangedate')"
                      @mouseleave="mouseOver('freechangedate')"
                    >
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </a>
            </div>
          </div>

          <div
            v-if="
              dialogOldPolicychangedateData.free_before > 1 ||
              !dialogOldPolicychangedateData.free_before
            "
            class="mt-5"
          >
            <span>
              {{ $t("SigninPolicy.label_dialog_fee_changedate1") }}
              {{ dialogOldPolicychangedateData.free_before }}
              {{ $t("SigninPolicy.label_dialog_fee_changedate2") }}
            </span>
            <input
              type="number"
              min="0"
              @keypress="isNumber($event)"
              class="form-control mt-3"
              placeholder
              name
              v-model="dialogOldPolicychangedateData.fee"
            />
          </div>

          <div
            v-if="
              dialogOldPolicychangedateData.free_before > 1 ||
              !dialogOldPolicychangedateData.free_before
            "
            class="mt-5"
          >
            <span>{{ $t("SigninPolicy.label_dialog_cantchagedate") }}</span>
            <div
              class="dropdown select mt-3"
              @click="getfocus('freechangedate')"
              @mouseleave="mouseOver('freechangedate')"
            >
              <a data-toggle="dropdown" data-display="static" id="freechangedate">
                <v-select
                  v-model="dialogOldPolicychangedateData.can_before"
                  :items="itemsOldCanBeforeFunc"
                  item-text="name"
                  item-value="value"
                  label
                  :placeholder="$t('SignupStep.select_one')"
                  :no-data-text="$t('SignupStep.nodata')"
                  color="pink"
                  solo
                  flat
                  dense
                  single-line
                  hide-details
                  @change="
                    checkChangeOldDateItems(dialogOldPolicychangedateData, 'start')
                  "
                >
                  <template v-slot:item="data">
                    <v-list-item-content
                      @mouseenter="getfocus('freechangedate')"
                      @mouseleave="mouseOver('freechangedate')"
                    >
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </a>
            </div>
          </div>

          <div class="mt-5">
            <div class="txt-bold">{{ $t("SigninPolicy.dialog_policy_title") }}</div>
            <span>
              {{ $t("SigninPolicy.dialog_policy_label1") }}
              {{ dialogOldPolicychangedateData.free_before }}
              {{ $t("SigninPolicy.dialog_policy_label2") }}
              {{ dialogOldPolicychangedateData.free_before }}
              {{
                dialogOldPolicychangedateData.free_before > 1
                  ? `${$t("SigninPolicy.dialog_policy_label3")} ${
                      dialogOldPolicychangedateData.can_before
                    } ${$t("SigninPolicy.dialog_policy_label4")}`
                  : $t("SigninPolicy.dialog_policy_label3_0")
              }}
            </span>
          </div>

          <div class="mt-5">
            <div class="txt-bold">{{ $t("SigninPolicy.title_payment_policy") }}</div>
            <div class="box-note-dialog pa-5 mt-1">
              <div>{{ $t("SigninPolicy.label_payment_policy") }}</div>
              <span>
                <span class="txt-bold">
                  {{ $t("SigninPolicy.note_payment_policy") }}
                </span>
                {{ $t("SigninPolicy.label_note_payment_policy") }}
              </span>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red lighten-2" outlined @click="closeDialogchangedateFunc()">{{
            $t("SigninPolicy.btn_cancel")
          }}</v-btn>
          <v-btn
            color="#666ee8"
            dark
            outlined
            :disabled="
              (dialogOldPolicychangedateData.free_before > 1 &&
                !dialogOldPolicychangedateData.can_before) ||
              dialogOldPolicychangedateData.fee < 0 ||
              !dialogOldPolicychangedateData.free_before
            "
            @click="saveDialogchangedateFunc(dialogOldPolicychangedateData)"
            >{{ $t("SigninPolicy.btn_save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog edit extra bed child data -->
    <v-dialog id="extra-bed-child" v-model="dialogExtraBedChild" max-width="40rem">
      <v-card v-if="loadingExtraBedChild">
        <v-card-text class="py-10 d-flex align-center justify-center">
          <img :src="require('@/assets/img/thumb/loading.gif')" class="img-gif-loading" />
        </v-card-text>
      </v-card>
      <v-card tile v-else @keydown.esc="closeDialogExtraBedChildFunc()">
        <div class="header-dialog">
          <span class="title-dialog-extrabed-policy">{{
            $t("SigninPolicy.title_extrabed_child1")
          }}</span>
          <v-spacer />
          <v-icon small @click="closeDialogExtraBedChildFunc()">mdi-close</v-icon>
        </div>
        <v-card-text class>
          <span class="txt-bold">
            {{ $t("SigninPolicy.title_extrabed_child2") }}
          </span>
          <v-row
            row
            wrap
            style="max-height: 50vh !important"
            class="overflow-y-auto mt-0"
          >
            <template v-for="(item, index) in dialogPolicyExtraBedChildData">
              <v-col cols="12" :key="item._id + 999" :class="index == 0 && 'pt-0'">
                <v-row>
                  <v-col cols="12" lg="12" md="12" sm="12" class="txt-bold mt-5">
                    <span class="label-dialog-extrabed-policy">{{
                      $t("SigninPolicy.age")
                    }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                    :class="
                      itemsAgeMax(item, index).length &&
                      itemsAgeMax(item, index)[0].value !== item.age.min
                        ? 'd-flex flex-row align-center justify-center'
                        : 'd-flex flex-row align-center justify-start'
                    "
                  >
                    <v-row class="px-0">
                      <v-col
                        cols="12"
                        :lg="
                          itemsAgeMax(item, index).length &&
                          itemsAgeMax(item, index)[0].value !== item.age.min
                            ? '6'
                            : '12'
                        "
                        :md="
                          itemsAgeMax(item, index).length &&
                          itemsAgeMax(item, index)[0].value !== item.age.min
                            ? '6'
                            : '12'
                        "
                        sm="12"
                        class="d-flex flex-row align-center justify-start"
                      >
                        <!-- <div class="mr-3">
                          {{
                          itemsAgeMax(item, index).length &&
                          itemsAgeMax(item, index)[0].value !== item.age.min
                          ? $t("SigninPolicy.since")
                          : $t("SigninPolicy.lnomorethan")
                          }}
                        </div>-->
                        <div
                          v-if="
                            itemsAgeMax(item, index).length &&
                            itemsAgeMax(item, index)[0].value !== item.age.min
                          "
                          class="mr-3"
                        >
                          {{ $t("SigninPolicy.since") }}
                        </div>
                        <div
                          class="dropdown select"
                          @click="getfocus(`agebefore-${item._id}`)"
                          @mouseleave="mouseOver(`agebefore-${item._id}`)"
                          style="width: 100%"
                        >
                          <a
                            data-toggle="dropdown"
                            data-display="static"
                            :id="`agebefore-${item._id}`"
                          >
                            <v-select
                              v-model="item.age.min"
                              :items="itemsAgeMin(item, index)"
                              item-text="name"
                              item-value="value"
                              label
                              :placeholder="$t('SignupStep.select_one')"
                              :no-data-text="$t('SignupStep.nodata')"
                              color="pink"
                              solo
                              flat
                              dense
                              single-line
                              hide-details
                              @change="itemsAgeMax(item, index)"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content
                                  @mouseenter="getfocus(`agebefore-${item._id}`)"
                                  @mouseleave="mouseOver(`agebefore-${item._id}`)"
                                >
                                  <v-list-item-title
                                    v-html="data.item.name"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                              <template v-slot:no-data>
                                <v-btn
                                  @click="itemsAgeMax(item, index)"
                                  text
                                  color="#666ee8"
                                  style="width: 100%"
                                  >{{ $t("SignupStep.btn_refresh") }}</v-btn
                                >
                              </template>
                            </v-select>
                          </a>
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          itemsAgeMax(item, index).length &&
                          itemsAgeMax(item, index)[0].value !== item.age.min
                        "
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                        class="d-flex flex-row align-center justify-start"
                      >
                        <div class="mr-3">{{ $t("SigninPolicy.to") }}</div>
                        <div
                          class="dropdown select"
                          @click="getfocus(`ageafter-${item._id}`)"
                          @mouseleave="mouseOver(`ageafter-${item._id}`)"
                          style="width: 100%"
                        >
                          <a
                            data-toggle="dropdown"
                            data-display="static"
                            :id="`ageafter-${item._id}`"
                          >
                            <v-select
                              v-model="item.age.max"
                              :items="itemsAgeMax(item, index)"
                              item-text="name"
                              item-value="value"
                              label
                              :placeholder="$t('SignupStep.select_one')"
                              :no-data-text="$t('SignupStep.nodata')"
                              color="pink"
                              solo
                              flat
                              dense
                              single-line
                              hide-details
                              @change="itemsAgeMin(item, index)"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content
                                  @mouseenter="getfocus(`ageafter-${item._id}`)"
                                  @mouseleave="mouseOver(`ageafter-${item._id}`)"
                                >
                                  <v-list-item-title
                                    v-html="data.item.name"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                              <template v-slot:no-data>
                                <v-btn
                                  @click="itemsAgeMin(item, index)"
                                  text
                                  color="#666ee8"
                                  style="width: 100%"
                                  >{{ $t("SignupStep.btn_refresh") }}</v-btn
                                >
                              </template>
                            </v-select>
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" class="txt-bold">
                    <span class="label-dialog-extrabed-policy">{{
                      $t("SigninPolicy.price")
                    }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                    class="d-flex flex-row align-center justify-start"
                  >
                    <v-row class="px-0">
                      <v-col
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                        class="d-flex flex-row align-center justify-start"
                      >
                        <div
                          class="dropdown select"
                          @click="getfocus(`percent-${item._id}`)"
                          @mouseleave="mouseOver(`percent-${item._id}`)"
                          style="width: 100%"
                        >
                          <a
                            data-toggle="dropdown"
                            data-display="static"
                            :id="`percent-${item._id}`"
                          >
                            <v-select
                              v-model="item.percent"
                              :items="itemsPriceExtraBed"
                              item-text="name"
                              item-value="value"
                              label
                              :placeholder="$t('SignupStep.select_one')"
                              :no-data-text="$t('SignupStep.nodata')"
                              color="pink"
                              solo
                              flat
                              dense
                              single-line
                              hide-details
                            >
                              <template v-slot:item="data">
                                <v-list-item-content
                                  @mouseenter="getfocus(`percent-${item._id}`)"
                                  @mouseleave="mouseOver(`percent-${item._id}`)"
                                >
                                  <v-list-item-title
                                    v-html="data.item.name"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </a>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                        class="d-flex flex-row align-center justify-start"
                      >
                        <div
                          class="dropdown select"
                          @click="getfocus(`perguestornight-${item._id}`)"
                          @mouseleave="mouseOver('`perguestornight-${item._id}`')"
                          style="width: 100%"
                        >
                          <a
                            data-toggle="dropdown"
                            data-display="static"
                            :id="`perguestornight-${item._id}`"
                          >
                            <v-select
                              v-model="item.perguestornight"
                              :items="itemsPerExtraBed"
                              item-text="name"
                              item-value="value"
                              label
                              :placeholder="$t('SignupStep.select_one')"
                              :no-data-text="$t('SignupStep.nodata')"
                              color="pink"
                              solo
                              flat
                              dense
                              single-line
                              hide-details
                            >
                              <template v-slot:item="data">
                                <v-list-item-content
                                  @mouseenter="getfocus(`perguestornight-${item._id}`)"
                                  @mouseleave="mouseOver(`perguestornight-${item._id}`)"
                                >
                                  <v-list-item-title
                                    v-html="data.item.name"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    :lg="$store.state.language == 'en' ? '6' : '4'"
                    :md="$store.state.language == 'en' ? '6' : '4'"
                    sm="12"
                    class="mt-4"
                  >
                    <v-btn
                      color="#ff4961"
                      dark
                      depressed
                      :disabled="index == 0 && dialogPolicyExtraBedChildData.length == 1"
                      @click="removeExtraBedPolicy(item, index)"
                      style="width: 100%; min-height: 3rem"
                    >
                      <v-icon left dark>mdi-close-circle-outline</v-icon>
                      {{ $t("SigninPolicy.btn_remove_requirement") }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    :lg="$store.state.language == 'en' ? '6' : '4'"
                    :md="$store.state.language == 'en' ? '6' : '4'"
                    sm="12"
                    class="mt-4"
                  >
                    <v-btn
                      v-if="
                        index == dialogPolicyExtraBedChildData.length - 1 &&
                        canAddExtraBedChild(item, index)
                      "
                      color="#666ee8"
                      dark
                      depressed
                      @click="addNewExtraBedRowFunc(item)"
                      :disabled="!item.age.min || !item.age.max || !item.percent"
                      style="width: 100%; min-height: 3rem"
                    >
                      <v-icon left dark>mdi-plus-circle-outline</v-icon>
                      {{ $t("SigninPolicy.btn_add_requirement") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                :key="index"
                v-if="index < dialogPolicyExtraBedChildData.length - 1"
                style="margin-top: -3%; margin-bottom: -6%"
              >
                <v-divider></v-divider>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <span v-if="warnfillextrabed" class="mt-3 mx-6" style="color: #e57373 !important"
          >*{{ $t("Alert.fillinfo") }}</span
        >

        <v-card-actions
          class="mt-3 pb-3 d-flex flex-row align-center justify-end footer-dialog"
        >
          <!-- <div
            class="my-5 d-flex flex-row align-center justify-end"
            style="width: 100%"
          >-->
          <v-btn outlined @click="closeDialogExtraBedChildFunc()">
            {{ $t("SigninPolicy.btn_cancel") }}
          </v-btn>
          <v-btn
            outlined
            color="#666ee8"
            class="ml-3"
            @click="saveDialogextrabedchildFunc(addNewExtraBedChild)"
            >{{ $t("SigninPolicy.btn_save") }}</v-btn
          >
          <!-- </div> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- </div> -->
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import { setFips } from "crypto";

export default {
  name: "SigninPolicyScreen",
  metaInfo: {
    title: "Policy",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    propertyData: null,
    value_element: "",
    policychangedate: {
      day: 1,
    },
    dialogPolicychangedate: false,
    dialogAddPolicychangedate: false,
    dialogOldPolicychangedateData: {
      can_before: 0,
      fee: 0,
      free_before: 0,
    },
    dialogPolicychangedateData: {
      can_before: 0,
      fee: 0,
      free_before: 0,
    },
    itemsPolicychangedate: [],
    quotaPolicychangedate: 6,
    itemsFreeBefore: [],
    itemsSelectFreeBefore: [],
    itemsSelectOldFreeBefore: [],
    itemsCanBefore: [],
    itemsOldCanBefore: [],
    itemsPolicyExtraBedChild: [],
    loadingExtraBedChild: false,
    dialogExtraBedChild: false,
    dialogPolicyExtraBedChildData: [],
    itemsOldPolicyExtraBed: [],
    warnfillextrabed: false,
    itemsChoiceSince: [],
    itemsAgeStart: [],
    itemsAgeEnd: [],
    itemsPriceExtraBed: [],
    itemsPerExtraBed: [],
    addNewExtraBedChild: {
      addnew: false,
      since: "since",
      age: {
        min: "",
        max: "",
      },
      percent: 10,
      perguestornight: "perguestandroom",
    },
    dialogCancelBooking: false,
    policyCancelBooking: null,
    dialogCustomer: false,
    policyCustomerData: {
      check_in_range: {
        is24hours: false,
        min: "14:00",
        max: "14:00",
      },
      check_out_range: {
        is24hours: false,
        min: "12:00",
        max: "12:00",
      },
      age: {
        isStrict: false,
        min: null,
        max: null,
      },
    },
    dialogPetParkPolicy: false,
    policyPet: null,
    itemsAllowPet: [],
    policyParking: null,
    itemsParking: [],
    itemsCheckInOut: [
      {
        value: "00:00",
        name: "00:00",
      },
      {
        value: "01:00",
        name: "01:00",
      },
      {
        value: "02:00",
        name: "02:00",
      },
      {
        value: "03:00",
        name: "03:00",
      },
      {
        value: "04:00",
        name: "04:00",
      },
      {
        value: "05:00",
        name: "05:00",
      },
      {
        value: "06:00",
        name: "06:00",
      },
      {
        value: "07:00",
        name: "07:00",
      },
      {
        value: "08:00",
        name: "08:00",
      },
      {
        value: "09:00",
        name: "09:00",
      },
      {
        value: "10:00",
        name: "10:00",
      },
      {
        value: "11:00",
        name: "11:00",
      },
      {
        value: "12:00",
        name: "12:00",
      },
      {
        value: "13:00",
        name: "13:00",
      },
      {
        value: "14:00",
        name: "14:00",
      },
      {
        value: "15:00",
        name: "15:00",
      },
      {
        value: "16:00",
        name: "16:00",
      },
      {
        value: "17:00",
        name: "17:00",
      },
      {
        value: "18:00",
        name: "18:00",
      },
      {
        value: "19:00",
        name: "19:00",
      },
      {
        value: "20:00",
        name: "20:00",
      },
      {
        value: "21:00",
        name: "21:00",
      },
      {
        value: "22:00",
        name: "22:00",
      },
      {
        value: "23:00",
        name: "23:00",
      },
    ],
    itemsAgePolicyCustomer: [],
    itemsAgePolicyCustomerMin: [],
    itemsAgePolicyCustomerMax: [],
    maxofminChild: 0,
    maxofmaxChild: 0,
    loadingData: false,
    itemsRatePlan: [],
    itemsRatePlanHavePolicy: [],
    checkremove: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  watch: {
    dialogAddPolicychangedate() {
      this.initPolicyChangeDateSelect();
    },
    itemsSelectFreeBefore() {
      // console.log("itemsSelectFreeBefore: ", this.itemsSelectFreeBefore);
    },
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  computed: {
    itemsSelectFreeBeforeFunc() {
      const self = this;
      var temp = [];
      self?.itemsFreeBefore?.map((el) => {
        const findValue = self?.itemsPolicychangedate?.find(
          (p) => p.free_before == el.value
        );
        if (!findValue?._id) temp.push(el);
        else if (self.dialogPolicychangedateData?.free_before == el.value) temp.push(el);
      });
      self.itemsSelectFreeBefore = temp;
      return temp;
    },
    itemsCanBeforeFunc() {
      const self = this;
      var tempArray = [];
      self.itemsSelectFreeBefore = self.itemsFreeBefore;
      self.itemsCanBefore = self.itemsCanBefore;
      tempArray = self?.itemsCanBefore?.filter(
        (p) => p.value < self.dialogPolicychangedateData?.free_before
      );
      // if (tempArray?.length)
      //   tempArray.unshift({
      //     value: 0,
      //     name: self.$t("RateAndInventory.add_rateplan_change_date3")
      //   });
      return tempArray;
    },
    itemsOldCanBeforeFunc() {
      const self = this;
      var tempArray = [];
      self.itemsSelectOldFreeBefore = self.itemsFreeBefore;
      self.itemsOldCanBefore = self.itemsCanBefore;
      tempArray = self?.itemsCanBefore?.filter(
        (p) => p.value < self.dialogOldPolicychangedateData?.free_before
      );
      // if (tempArray?.length)
      //   tempArray.unshift({
      //     value: 0,
      //     name: self.$t("RateAndInventory.add_rateplan_change_date3")
      //   });
      return tempArray;
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    initAll() {
      const self = this;
      self.initPolicyChangeDate();
      self.initPolicyCustomerDetail();
      self.initExtraBedChildData();
      self.initParkingData();
      self.initPropertyData();
      self.initRatePlan();
    },
    initPolicyChangeDate() {
      const self = this;
      self.itemsFreeBefore = [
        {
          value: 1,
          name: `${self.$t("SigninPolicy.before_checkin")} 1 ${self.$t(
            "SigninPolicy.day"
          )}`,
        },
        {
          value: 3,
          name: `${self.$t("SigninPolicy.before_checkin")} 3 ${self.$t(
            "SigninPolicy.day"
          )}`,
        },
        {
          value: 5,
          name: `${self.$t("SigninPolicy.before_checkin")} 5 ${self.$t(
            "SigninPolicy.day"
          )}`,
        },
        {
          value: 7,
          name: `${self.$t("SigninPolicy.before_checkin")} 7 ${self.$t(
            "SigninPolicy.day"
          )}`,
        },
        {
          value: 15,
          name: `${self.$t("SigninPolicy.before_checkin")} 15 ${self.$t(
            "SigninPolicy.day"
          )}`,
        },
        {
          value: 30,
          name: `${self.$t("SigninPolicy.before_checkin")} 30 ${self.$t(
            "SigninPolicy.day"
          )}`,
        },
      ];
      self.itemsCanBefore = [
        {
          value: 1,
          name: `${self.$t("SigninPolicy.before_checkin2")} 1 ${self.$t(
            "SigninPolicy.day"
          )} ${self.$t("SigninPolicy.before_checkin")}`,
        },
        {
          value: 3,
          name: `${self.$t("SigninPolicy.before_checkin2")} 3 ${self.$t(
            "SigninPolicy.day"
          )} ${self.$t("SigninPolicy.before_checkin")}`,
        },
        {
          value: 5,
          name: `${self.$t("SigninPolicy.before_checkin2")} 5 ${self.$t(
            "SigninPolicy.day"
          )} ${self.$t("SigninPolicy.before_checkin")}`,
        },
        {
          value: 7,
          name: `${self.$t("SigninPolicy.before_checkin2")} 7 ${self.$t(
            "SigninPolicy.day"
          )} ${self.$t("SigninPolicy.before_checkin")}`,
        },
        {
          value: 15,
          name: `${self.$t("SigninPolicy.before_checkin2")} 15 ${self.$t(
            "SigninPolicy.day"
          )} ${self.$t("SigninPolicy.before_checkin")}`,
        },
        {
          value: 30,
          name: `${self.$t("SigninPolicy.before_checkin2")} 30 ${self.$t(
            "SigninPolicy.day"
          )} ${self.$t("SigninPolicy.before_checkin")}`,
        },
      ];
    },
    initPolicyChangeDateSelect(data) {
      const self = this;
      var temp = [];
      self?.itemsFreeBefore?.map((el) => {
        const findValue = self?.itemsPolicychangedate?.find(
          (p) => p.free_before == el.value
        );
        if (!findValue?._id) temp.push(el);
        else if (data?.free_before == el.value) temp.push(el);
      });
      // console.log('itemsFreeBefore: ', self.itemsFreeBefore, temp)
      self.itemsSelectFreeBefore = temp;
      return temp;
    },
    initPolicyChangeDateSelectOld(item) {
      const self = this;
      self.dialogPolicychangedate = true;
      if (item?._id) {
        self.dialogOldPolicychangedateData._id = item._id;
        self.dialogOldPolicychangedateData.free_before = item.free_before;
        self.dialogOldPolicychangedateData.fee = item.fee;
        self.dialogOldPolicychangedateData.can_before = item.can_before;
        self.itemsSelectOldFreeBefore = self.initPolicyChangeDateSelect(item);
        self.checkChangeOldDateItems(item, "end");
      }
    },
    inititemsPolicy(data) {
      const self = this;
      var temp = [];
      // console.log("datadatadata: ", data);
      self.itemsFreeBefore.map((el) => {
        const findValue = self?.itemsPolicychangedate?.find(
          (p) => p.free_before == el.value
        );
        if (!findValue?._id) temp.push(el);
        else if (data?.free_before == el.value) temp.push(el);
      });
      return temp;
    },
    initExtraBedChildData() {
      const self = this;
      var i = 1;
      var tempAges = [];
      self.itemsChoiceSince = [
        {
          value: "since",
          name: self.$t("SigninPolicy.since"),
        },
      ];

      for (var i = 1; i < 13; i++) {
        tempAges.push({
          value: i,
          name: i,
        });
      }
      self.itemsAge = tempAges;
      self.itemsAgeStart = tempAges;
      self.itemsAgeEnd = tempAges;
      self.itemsPriceExtraBed = [
        {
          value: 10,
          name: `10% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
        {
          value: 20,
          name: `20% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
        {
          value: 30,
          name: `30% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
        {
          value: 40,
          name: `40% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
        {
          value: 50,
          name: `50% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
        {
          value: 60,
          name: `60% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
        {
          value: 70,
          name: `70% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
        {
          value: 80,
          name: `80% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
        {
          value: 90,
          name: `90% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
        {
          value: 100,
          name: `100% ${self.$t("SigninPolicy.percentofextrabed")}`,
        },
      ];
      self.itemsPerExtraBed = [
        {
          value: "perguestandroom",
          name: `${self.$t("SigninPolicy.perguest")}${self.$t(
            "SigninPolicy.andperextrabed"
          )}${self.$t("SigninPolicy.pernight")}`,
        },
      ];
    },
    async initRatePlan() {
      const self = this;
      var temp = [];
      var tempHavePolicy = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?property_id=${self.propertyID}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            temp = res?.data?.result || [];
            res?.data?.result?.map((el) => {
              if (el?.policy_change_date) tempHavePolicy.push(el);
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.itemsRatePlan = temp;
        self.itemsRatePlanHavePolicy = tempHavePolicy;
        // console.log("rateplan: ", temp);
      }
    },
    checkAgeItems(item, value) {
      const self = this;
      var tempArray = [];
      self.itemsAgeStart = self.itemsAge;
      self.itemsAgeEnd = self.itemsAge;
      var data = [];
      if (value == "min") {
        tempArray = self?.itemsAge?.filter((p) => p.value > item?.age?.min);
        item.itemsAgeEnd = tempArray;
        self.itemsAgeEnd = tempArray;
      } else {
        tempArray = self?.itemsAge?.filter((p) => p.value < item?.age?.max);
        item.itemsAgeStart = tempArray;
        self.itemsAgeStart = tempArray;
      }
      return data;
    },
    itemsAgeMin(item, index) {
      const self = this;
      self.itemsAgeStart = self.itemsAge;
      self.itemsAgeEnd = self.itemsAge;
      var data = [];
      var rowAboveData = {};
      var rowUnderData = {};
      rowAboveData =
        index > 0
          ? self?.dialogPolicyExtraBedChildData[index - 1]
          : self?.dialogPolicyExtraBedChildData[index];
      rowUnderData = self?.dialogPolicyExtraBedChildData[index + 1];

      const r = self?.itemsAge.filter((elem) =>
        !rowAboveData?.age?.max
          ? elem?.value < item?.age?.max
          : item?.age?.max &&
            rowUnderData?.age?.min &&
            self?.dialogPolicyExtraBedChildData[index - 1]?.age?.max
          ? (elem?.value < item?.age?.max && elem.value > rowAboveData?.age?.max) ||
            elem?.value == item?.age?.min
          : item?.age?.max && rowUnderData?.age?.min
          ? elem?.value < item?.age?.max || elem?.value == item?.age?.min
          : elem.value > rowAboveData?.age?.max || elem?.value == item?.age?.min
      );

      if (index == 0 && (!item?.age?.max || item?.age?.min == item?.age?.max)) {
        data = self.itemsAgeStart;
      } else data = r;
      return data;
    },
    itemsAgeMax(item, index) {
      const self = this;
      self.itemsAgeStart = self.itemsAge;
      self.itemsAgeEnd = self.itemsAge;
      var data = [];
      var rowAboveData = {};
      var rowUnderData = {};
      rowAboveData =
        index > 0
          ? self?.dialogPolicyExtraBedChildData[index - 1]
          : self?.dialogPolicyExtraBedChildData[index];
      rowUnderData = self?.dialogPolicyExtraBedChildData[index + 1];

      const r = self?.itemsAge.filter((elem) =>
        !item?.age?.min
          ? elem.value > rowAboveData?.age?.max && elem.value < rowUnderData?.age?.min
          : item?.age?.min == 12
          ? elem?.value > item?.age?.min
          : item?.age?.min && rowUnderData
          ? (elem?.value > item?.age?.min && elem.value < rowUnderData?.age?.min) ||
            elem?.value == item?.age?.max
          : elem?.value > item?.age?.min || elem?.value == item?.age?.max
      );

      if (index == 0 && !self?.dialogPolicyExtraBedChildData[index + 1]?.age?.min) {
        data = self?.itemsAge?.filter((p) => p.value > item?.age?.min);
      } else data = r;
      if (!data?.length) item.age.max = item?.age?.min;
      return data;
    },
    canAddExtraBedChild(item, index) {
      const self = this;
      var tmpResult = false;
      if (
        self.itemsAgeMin(item, index)?.length &&
        item.age.min !==
          self.itemsAgeMin(item, index)[self.itemsAgeMin(item, index)?.length - 1]
            ?.value &&
        self.itemsAgeMax(item, index)?.length &&
        item.age.max !==
          self.itemsAgeMax(item, index)[self.itemsAgeMax(item, index)?.length - 1]?.value
      ) {
        tmpResult = true;
      } else tmpResult = false;
      // console.log(
      //   "min: ",
      //   item.age.min,
      //   self.itemsAgeMin(item, index)?.length,
      //   self.itemsAgeMin(item, index)
      // );
      // console.log(
      //   "max: ",
      //   item.age.max,
      //   self.itemsAgeMax(item, index)?.length,
      //   self.itemsAgeMax(item, index)
      // );
      return tmpResult;
    },
    checkChangeDateItems(item, value) {
      const self = this;
      var tempArray = [];
      self.itemsSelectFreeBefore = self.itemsFreeBefore;
      self.itemsCanBefore = self.itemsCanBefore;
      // console.log(self.itemsFreeBefore);
      if (value == "start") {
        // tempArray = self?.itemsFreeBefore?.filter(
        //   (p) => p.value > item?.can_before
        // );
        // item.itemsSelectFreeBefore = tempArray;
        // self.itemsSelectFreeBefore = tempArray;
      } else {
        tempArray = self?.itemsCanBefore?.filter((p) => p.value <= item?.free_before);
        item.itemsCanBefore = tempArray;
        self.itemsCanBefore = tempArray;
      }
    },
    checkChangeOldDateItems(item, value) {
      const self = this;
      var tempArray = [];
      self.itemsSelectOldFreeBefore = self.itemsFreeBefore;
      self.itemsOldCanBefore = self.itemsCanBefore;
      // console.log(self.itemsFreeBefore);
      if (value == "start") {
        // tempArray = self?.itemsFreeBefore?.filter(
        //   (p) => p.value > item?.can_before
        // );
        // item.itemsSelectFreeBefore = tempArray;
        // self.itemsSelectFreeBefore = tempArray;
      } else {
        tempArray = self?.itemsCanBefore?.filter((p) => p.value <= item?.free_before);
        item.itemsOldCanBefore = tempArray;
        self.itemsOldCanBefore = tempArray;
      }
    },
    initPolicyCustomerDetail() {
      const self = this;
      var tempAges = [
        {
          value: null,
          name: self.$t("SigninPolicy.label_no_limit_age"),
        },
      ];
      for (var i = 1; i < 100; i++) {
        tempAges.push({
          value: i,
          name: i,
        });
      }
      self.itemsAllowPet = [
        {
          value: false,
          name: self.$t("SigninPolicy.no"),
        },
        {
          value: true,
          name: self.$t("SigninPolicy.yes"),
        },
      ];
      self.itemsAgePolicyCustomer = tempAges;
    },
    checkCustomerStrictAge() {
      const self = this;
      if (!self.policyCustomerData.age.isStrict) {
        self.policyCustomerData.age.min = null;
        self.policyCustomerData.age.max = null;
      }
    },
    checkAgeCustomerItems(value) {
      const self = this;
      var tempArray = [];
      self.itemsAgePolicyCustomerMin = self.itemsAgePolicyCustomer;
      self.itemsAgePolicyCustomerMax = self.itemsAgePolicyCustomer;
      if (value == "min") {
        tempArray = self?.itemsAgePolicyCustomer?.filter(
          (p) => p.value > self.policyCustomerData.age.min
        );
        tempArray.push({
          value: null,
          name: self.$t("SigninPolicy.label_no_limit_age"),
        });
        self.itemsAgePolicyCustomerMax = tempArray;
        self.itemsAgePolicyCustomerMax.sort((a, b) => (a.value > b.value ? 1 : -1));
      } else if (value == "max") {
        tempArray = self?.itemsAgePolicyCustomer?.filter(
          (p) => p.value < self.policyCustomerData.age.max
        );
        self.itemsAgePolicyCustomerMin = tempArray;
      } else {
        if (self?.policyCustomerData?.age?.min) {
          self.itemsAgePolicyCustomerMax = self?.itemsAgePolicyCustomer?.filter(
            (p) => p.value > self.policyCustomerData.age.min
          );
          self.itemsAgePolicyCustomerMax.push({
            value: null,
            name: self.$t("SigninPolicy.label_no_limit_age"),
          });
          self.itemsAgePolicyCustomerMax.sort((a, b) => (a.value > b.value ? 1 : -1));
        }
        if (self?.policyCustomerData?.age?.max)
          self.itemsAgePolicyCustomerMin = self?.itemsAgePolicyCustomer?.filter(
            (p) => p.value < self.policyCustomerData.age.max
          );
      }
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempChangeDate = [];
      var tempExtraBedChild = [];
      var tempAgeStart, tempAgeEnd;
      self.loadingData = true;
      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          // console.log("prop data: ", temp);
          if (temp?.policy_change_date?.length) {
            temp?.policy_change_date?.map((el) => {
              const findPolicy = self.itemsRatePlanHavePolicy?.find(
                (p) => p.policy_change_date?._id == el?._id
              );
              // console.log('findPolicy: ', findPolicy)
              tempChangeDate.push({
                ...el,
                in_used: findPolicy?._id ? true : false,
              });
            });
          }
          if (temp?.policy_child?.length) {
            temp?.policy_child?.map((el) => {
              tempAgeStart = self?.itemsAge?.filter((p) => p.value < el?.age?.max);
              tempAgeEnd = self?.itemsAge?.filter((p) => p.value > el?.age?.min);
              tempExtraBedChild.push({
                ...el,
                addnew: false,
                since: "since",
                agebefore: el?.age?.min || "",
                ageafter: el?.age?.max || "",
                perguestornight: "perguestandroom",
                itemsAgeStart: tempAgeStart,
                itemsAgeEnd: tempAgeEnd,
              });
              tempExtraBedChild.sort((a, b) => (a.agebefore > b.agebefore ? 1 : -1));
              self.dialogPolicyExtraBedChildData = tempExtraBedChild;
            });
            self.itemsOldPolicyExtraBed = temp?.policy_child || [];
          } else {
            if (self?.dialogPolicyExtraBedChildData?.length == 0)
              self.addNewExtraBedRowFunc();
          }
          self.policyCancelBooking = temp.policy_cancel_booking;

          if (temp?.policy_customer) {
            self.policyCustomerData = {
              check_in_range: {
                is24hours:
                  temp?.policy_customer?.check_in_range?.min == "00:00" &&
                  temp?.policy_customer?.check_in_range?.max == "00:00"
                    ? true
                    : false,
                min: temp?.policy_customer?.check_in_range?.min,
                max: temp?.policy_customer?.check_in_range?.max,
              },
              check_out_range: {
                is24hours:
                  temp?.policy_customer?.check_out_range?.min == "00:00" &&
                  temp?.policy_customer?.check_out_range?.max == "00:00"
                    ? true
                    : false,
                min: temp?.policy_customer?.check_out_range?.min,
                max: temp?.policy_customer?.check_out_range?.max,
              },
              age: {
                isStrict:
                  temp?.policy_customer?.age?.min || temp?.policy_customer?.age?.max
                    ? true
                    : false,
                min: temp?.policy_customer?.age?.min || null,
                max: temp?.policy_customer?.age?.max || null,
              },
            };
          } else {
            self.policyCustomerData.check_in_range.min = temp?.check_in || "12:00";
            self.policyCustomerData.check_in_range.max = temp?.check_in || "14:00";
            self.policyCustomerData.check_out_range.min = temp?.check_out || "11:00";
            self.policyCustomerData.check_out_range.max = temp?.check_out || "12:00";
          }
          self.policyPet = temp.pet;

          if (temp?.parking && temp?.parking.length && temp?.parking != 'undefined') {
            self.policyParkingData = temp?.parking;
          }
          // self.policyParking = temp.parking;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        self.itemsPolicychangedate = tempChangeDate;
        // console.log("tempChangeDate: ", tempChangeDate);
        self.itemsPolicyExtraBedChild = tempExtraBedChild;
        self.checkAgeCustomerItems();
        self.loadingData = false;
      }
    },
    async initParkingData() {
      const self = this;
      self.itemsParking = [];
      var temp = [];
      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/setting/parking", {
          headers: {
            Authorization: self?.userToken,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res?.status == 200) {
          if (res?.data?.result?.length) {
            temp = res?.data?.result;
            console.log("parking data: ", temp);
          }
        }
        // console.log("initParkingData: ", res);
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsParking = temp;
        self.itemsParking.map((el, i) => {
          if (i == 0) {
            self.policyParking = el._id;
          }
        })
      }
    },
    checkParkingData(item) {
      const self = this;
      const temp = self?.itemsParking?.filter((p) => p._id == self.policyParking);
      return temp[0]?.value || self.$t("SigninPolicy.nopark");
    },
    checkInUsedPolicyRatePlanFunc(item) {
      const self = this;
      var canRamove = false;
      const findPolicy = self.itemsRatePlanHavePolicy?.find(
        (p) => p.policy_change_date?._id == item?._id
      );
      if (findPolicy?._id) canRamove = false;
      else canRamove = true;
      return canRamove;
    },
    async removeChangeDateFunc(item) {
      const self = this;
      self.initRatePlan();
      const findPolicy = self.itemsRatePlanHavePolicy?.find(
        (p) => p.policy_change_date?._id == item?._id
      );
      if (findPolicy?._id) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("SigninPolicy.policy_applied"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        EventBus.$emit("loadingtillend");
        try {
          const res = await self.axios.delete(
            process.env.VUE_APP_API +
              `/property/policy_change_date?policy_change_date_id=${item._id}`,
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem("Token") ||
                  self?.$store?.state?.resSignUpData?.access_token,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            "Warning",
            error?.response?.data?.message || "Please try again",
            "warning",
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          EventBus.$emit("endloading");
          self.initAll();
        }
      }
    },
    closeDialogchangedateFunc() {
      const self = this;
      self.dialogPolicychangedate = false;
      self.dialogAddPolicychangedate = false;
      self.dialogPolicychangedateData = {
        can_before: 0,
        fee: 0,
        free_before: 0,
      };
      self.dialogOldPolicychangedateData = {
        can_before: 0,
        fee: 0,
        free_before: 0,
      };
    },
    async saveDialogchangedateFunc(item) {
      const self = this;
      EventBus.$emit("loadingtillend");
      const obj = {
        can_before: item.can_before,
        fee: item.fee,
        free_before: item.free_before,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/policy_change_date?policy_change_date_id=${item._id}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.closeDialogchangedateFunc();
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.initAll();
      }
    },
    async addNewPolicychangedateFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const obj = {
        can_before: self.dialogPolicychangedateData.can_before,
        fee: self.dialogPolicychangedateData.fee,
        free_before: self.dialogPolicychangedateData.free_before,
      };
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API +
            `/property/policy_change_date?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.closeDialogchangedateFunc();
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.initAll();
      }
    },
    editDialogExtraBedChild() {
      const self = this;
      self.dialogExtraBedChild = true;
    },
    closeDialogExtraBedChildFunc() {
      const self = this;
      self.dialogExtraBedChild = false;
      self.dialogPolicyExtraBedChildData = [];
      self.warnfillextrabed = false;
      self.initAll();
    },
    async updateExtraBedPolicy(item) {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.loadingExtraBedChild = true;
      const obj = {
        age: {
          min: item.age.min,
          max: item.age.max,
        },
        percent: item.percent,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API + `/property/policy_child?policy_child_id=${item._id}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        self.loadingExtraBedChild = false;
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        self.loadingExtraBedChild = false;
        EventBus.$emit("endloading");
        self.initAll();
      }
    },
    async removeExtraBedPolicy(item, index) {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.loadingExtraBedChild = true;

      const oldiddata = self?.itemsOldPolicyExtraBed?.find((p) => p._id == item._id);

      // console.log("oldiddata: ", oldiddata);

      if (oldiddata?._id) {
        try {
          const res = await self.axios.delete(
            process.env.VUE_APP_API +
              `/property/policy_child?policy_child_id=${item._id}`,
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem("Token") ||
                  self?.$store?.state?.resSignUpData?.access_token,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
        } catch (error) {
          self.loadingExtraBedChild = false;
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            "Warning",
            error?.response?.data?.message || "Please try again",
            "warning",
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          self.loadingExtraBedChild = false;
          self.initAll();
          EventBus.$emit("endloading");
        }
      } else {
        setTimeout(() => {
          self?.dialogPolicyExtraBedChildData.splice(index, 1);
          self.loadingExtraBedChild = false;
          // EventBus.$emit("endloading");
        }, 500);
      }
    },
    async saveDialogextrabedchildFunc() {
      const self = this;
      var num = 0;
      var tempOldObj = [];
      var tempObj = [];
      self?.dialogPolicyExtraBedChildData?.map((el) => {
        const oldiddata = self?.itemsOldPolicyExtraBed?.find((p) => p._id == el._id);
        if (el?.age?.min && el?.age?.max && el?.percent) {
          if (oldiddata?._id) {
            tempOldObj.push({
              _id: el._id,
              age: {
                min: el.age.min,
                max: el.age.max,
              },
              percent: el.percent,
            });
          } else {
            tempObj.push({
              age: {
                min: el.age.min,
                max: el.age.max,
              },
              percent: el.percent,
            });
          }
          num++;
        } else {
          self.warnfillextrabed = true;
          var elementMin = document.getElementById(`agebefore-${el._id}`);
          var elementMax = document.getElementById(`ageafter-${el._id}`);
          var elementPercent = document.getElementById(`percent-${el._id}`);

          if (!el?.age?.min) {
            elementMin.classList.add("border-pink-focus");
          } else {
            elementMin?.classList?.remove("border-pink-focus");
          }
          if (!el?.age?.max) {
            elementMax.classList.add("border-pink-focus");
          } else {
            elementMax?.classList?.remove("border-pink-focus");
          }
          if (!el?.percent) {
            elementPercent.classList.add("border-pink-focus");
          } else {
            elementPercent?.classList?.remove("border-pink-focus");
          }
        }
      });

      if (num && num == self?.dialogPolicyExtraBedChildData?.length) {
        try {
          if (tempObj?.length) {
            const res = await self.axios.post(
              process.env.VUE_APP_API +
                `/property/policy_child?property_id=${self.propertyID}`,
              tempObj,
              {
                headers: {
                  Authorization:
                    self?.userToken ||
                    localStorage?.getItem("Token") ||
                    self?.$store?.state?.resSignUpData?.access_token,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
            if (res.status == 200) {
              self.loadingExtraBedChild = true;
              self.warnfillextrabed = false;
              self.closeDialogExtraBedChildFunc();
            }
          }
          if (tempOldObj?.length) {
            const res = await self.axios.put(
              process.env.VUE_APP_API +
                `/property/policy_child?property_id=${self.propertyID}`,
              tempOldObj,
              {
                headers: {
                  Authorization:
                    self?.userToken ||
                    localStorage?.getItem("Token") ||
                    self?.$store?.state?.resSignUpData?.access_token,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
            if (res.status == 200) {
              self.loadingExtraBedChild = true;
              self.warnfillextrabed = false;
              self.closeDialogExtraBedChildFunc();
            }
          }
        } catch (error) {
          self.loadingExtraBedChild = false;
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            "Warning",
            error?.response?.data?.message || "Please try again",
            "warning",
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          EventBus.$emit("endloading");
          self.loadingExtraBedChild = false;
        }
      }
    },
    closeDialogcancelbookingFunc() {
      const self = this;
      self.dialogCancelBooking = false;
      self.initAll();
    },
    async saveDialogcancelbookingFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const obj = {
        policy_cancel_booking: self.policyCancelBooking,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/policy_cancel_booking?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.closeDialogcancelbookingFunc();
      }
    },
    closeDialogpetparkFunc() {
      const self = this;
      self.dialogPetParkPolicy = false;
      self.initAll();
    },
    async saveDialogpetparkFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const obj = {
        parking: self.policyParking,
        pet: self.policyPet,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/policy_pet_and_parking?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.closeDialogpetparkFunc();
      }
    },
    closeDialogcustomerFunc() {
      const self = this;
      self.dialogCustomer = false;
      self.initAll();
    },
    async saveDialogcustomerFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const obj = {
        check_in_range: {
          min: self.policyCustomerData.check_in_range.min,
          max: self.policyCustomerData.check_in_range.max,
        },
        check_out_range: {
          min: self.policyCustomerData.check_out_range.min,
          max: self.policyCustomerData.check_out_range.max,
        },
        age: {
          min: self.policyCustomerData.age.min,
          max: self.policyCustomerData.age.max,
        },
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/policy_customer?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.closeDialogcustomerFunc();
      }
    },
    checkIn24hours() {
      const self = this;
      if (self?.policyCustomerData?.check_in_range?.is24hours) {
        self.policyCustomerData.check_in_range.min = "00:00";
        self.policyCustomerData.check_in_range.max = "00:00";
      }
    },
    checkOut24hours() {
      const self = this;
      if (self?.policyCustomerData?.check_out_range?.is24hours) {
        self.policyCustomerData.check_out_range.min = "00:00";
        self.policyCustomerData.check_out_range.max = "00:00";
      }
    },
    addNewExtraBedRowFunc() {
      const self = this;
      var tempExtraBedChild = [];
      tempExtraBedChild = self.dialogPolicyExtraBedChildData;
      tempExtraBedChild.push({
        _id: self?.dialogPolicyExtraBedChildData?.length || 0,
        addnew: true,
        since: "since",
        age: {
          min: "",
          max: "",
        },
        agebefore: "",
        ageafter: "",
        perguestornight: "perguestandroom",
        itemsAgeStart: self?.itemsAge,
        itemsAgeEnd: self?.itemsAge,
      });
      setTimeout(() => {
        self.dialogPolicyExtraBedChildData = tempExtraBedChild;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.moving-left {
  /* height: 100px; */
  /* position: relative; */
  transition: transform 0.3s ease-in-out;
  transform: translateX(0px);
  /* width: 100px; */
}

.moving-left:hover {
  transform: translateX(-10px);
}

.hover_numberbtn:hover {
  border: solid 1px transparent;
  color: #fff !important;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.btn.btn-addtype:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}

.v-input__slot {
  border: 1px solid var(--border-gray) !important;
}

.title-green {
  color: var(--green) !important;
}

.title-gray {
  color: var(--gray) !important;
}

.navtop-canclick {
  cursor: pointer !important;
}

.btn[disabled] {
  min-height: 42px !important;
  height: fit-content !important;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  color: rgba(19, 1, 1, 0.3) !important;
  background-color: rgba(239, 239, 239, 0.7) !important;
}
</style>

<style scoped>
.label-policy {
  text-indent: 3rem;
  text-align: left;
  letter-spacing: 0.15rem;
}

.box-content-policty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.box-note-dialog {
  border: 1px solid #828282;
}

.theme--dark.v-btn.v-btn--disabled {
  color: rgba(19, 1, 1, 0.3) !important;
  background-color: rgba(239, 239, 239, 0.7) !important;
}
</style>

<style>
#extra-bed-child .v-dialog {
  overflow-y: hidden !important;
}
</style>
